<template>
  <div class="rounded-xl mt-28 pb-16">
    <div class="lg:py-8 lg:flex lg:justify-center gap-4 space-y-5 lg:space-y-0">
      <div class="lg:w-1/3 p-5 bg-gray-50 rounded-md shadow-xl">
        <h3 class="font-bold ml-1">{{ loc.benefit_budget }}</h3>
        <div class="bg-white shadow-xl rounded-md p-3 py-3 mt-6 flex items-center">
          <div class="flex items-center justify-between text-sm font-medium w-full">
            <p class="text-gray-700 font-bold"> {{loc.yearly_total_budget}}:</p>
            <div class="flex items-center">
              <p class="text-base-600">{{ monetary(totalBenefitBudget, user.currency)}}</p>
              <div class="tooltip">
                <InformationCircleIcon class="w-5 h-5 text-base-500" />
                <span class="tooltiptext shadow-xl">{{ loc.total_benefit_budget_tooltip }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white shadow-xl rounded-md p-3 space-y-3 mt-6">
          <div class="flex items-center justify-between text-sm font-medium w-full">
            <p class="text-gray-600 font-bold"> {{loc.flex_benefit_budget}}:</p>
            <p class="text-base-600">{{ monetary(user.base_balance, user.currency)}}</p>
          </div>
          <div class="flex items-center justify-between text-sm font-medium w-full">
            <p class="text-gray-600"> {{loc.benefits_bought}}: </p>
            <p class="text-yellow-500">{{user.chosen_benefits}}</p>
          </div>

          <div class="flex items-center justify-between text-sm font-medium w-full">
            <p class="text-gray-600"> {{loc.spent}}: </p>
            <p class="text-yellow-500">{{ monetary(user.spent, user.currency)}}</p>
          </div>

          <div class="flex items-center justify-between text-sm font-medium w-full">
            <p class="text-gray-600"> {{loc.current_balance}}: </p>
            <p class="text-base-600">{{ monetary(user.balance, user.currency)}}</p>
          </div>

          <div class="w-100 border bg-white h-2 rounded-md">
            <div class="bg-base-600 h-2 rounded-md" :style="`width: ${calculateBudgetPercent(user.spent, user.base_balance)}%`"></div>
          </div>
        </div>

        <div v-if="$store.state.auth.user.category_budgets.length" class="mt-8">
          <div v-for="b in $store.state.auth.user.category_budgets" class="space-y-3">
            <div class="bg-white shadow-xl rounded-md p-3 mt-8 space-y-3">
              <div class="flex items-center justify-between text-sm font-medium w-full">
                <p class="text-gray-700 font-bold"> {{loc.category}}: <span class="text-base-600">{{ parse_name(b.category) }}</span> </p>
                <p class="text-base-600">{{ monetary(b.amount, user.currency) }}</p>
              </div>
<!--              <div class="flex items-center justify-between text-sm font-medium w-full"></div>-->
<!--              <p class="block text-sm font-medium text-gray-600"> {{loc.yearly_total_budget}}: <span class="text-base-600">{{ monetary(b.amount, user.currency) }}</span> </p>-->
              <div class="flex items-center justify-between text-sm font-medium w-full">
                <p class="text-gray-600"> {{loc.spent}}: </p>
                <p class="text-yellow-500 ">{{ monetary(b.spent, user.currency)}}</p>
              </div>
              <div class="flex items-center justify-between text-sm font-medium w-full">
                <p class="text-gray-600"> {{loc.current_balance}}: </p>
                <span class="text-base-600">{{ monetary(b.amount - b.spent, user.currency)}}</span>
              </div>
              <div class="w-100 border bg-white h-2 rounded-md">
                <div class="bg-base-500 h-2 rounded-md" :style="`width: ${calculateBudgetPercent(b.spent, b.amount)}%`"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 p-4 space-y-4 bg-gray-50 rounded-md shadow-xl">
        <h3 class="font-bold">{{ loc.my_profile }}</h3>
        <div class="flex items-center gap-x-4">
          <div class="relative">
            <div class="absolute -top-3 -right-4 h-6 w-6 rounded-full border text-sm flex items-center justify-center text-base-600">
              <button @click="$refs.profile_pic.click()" type="button">
<!--                {{loc.add_photo}}-->
                <font-awesome-icon icon="fa-pencil" />
              </button>
              <input @change="upload_profile_pic($event)" hidden type="file" ref="profile_pic"/>
            </div>
            <div class="h-20 w-20 rounded-full overflow-hidden bg-gray-100">
              <img v-if="user.profile_picture" :src="user.profile_picture" class="w-full h-full">
              <svg v-else class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </div>
          </div>
          <p class="font-semibold text-base-500">{{user.first_name}} {{user.last_name}}</p>
        </div>
        <div class="flex items-center">
          <span :class="`justify-center mt-0.5 inline-flex flex-shrink-0 items-center rounded-full ring-1 ${user.is_company_admin ? 'bg-red-50 text-red-700 ring-red-600' : 'bg-green-50 text-green-700 ring-green-600 '} ring-opacity-50 px-3 py-0.5 text-xs font-medium`">
            {{ user.is_company_admin ? 'Admin' : loc.user }}
          </span>
        </div>

        <p class="block text-sm font-medium text-gray-700"> {{loc.company}}: <span class="text-base-600">{{user.company.name}}</span> </p>
        <p class="block text-sm font-medium text-gray-700"> {{loc.id_company}}: <span class="text-base-600">{{user.id_company}}</span> </p>
        <div class="flex">
          <p v-if="editable !== 'position'" class="block text-sm font-medium text-gray-700"> {{loc.position}}: <span class="text-base-600">{{user.position}}</span> </p>
<!--          <p v-if="editable != 'phone'" class="block text-sm font-medium text-gray-700"> {{loc.phone_number}}: <span class="text-base-600">{{user.phone}}</span></p>-->
          <input ref="position_input" v-else v-model="editable_value" type="text" class="shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-300 rounded-md h-8" />
          <pencil-icon v-if="editable !== 'position'" @click="editable = 'position'; editable_value = user.position;" class="ml-2 w-5 h-5 hover:text-base-600 cursor-pointer"/>
          <save-icon v-else class="ml-2 w-5 h-5 hover:text-base-600 cursor-pointer" @click="update_user({'position': $refs.position_input.value})"/>
        </div>
<!--        <p class="block text-sm font-medium text-gray-700"> {{loc.first_name}}: <span class="text-base-600">{{user.first_name}}</span> </p>-->
<!--        <p class="block text-sm font-medium text-gray-700"> {{loc.last_name}}: <span class="text-base-600">{{user.last_name}}</span> </p>-->
        <p class="block text-sm font-medium text-gray-700"> {{loc.email}}: <span class="text-base-600">{{user.email}}</span> </p>
        <p class="block text-sm font-medium text-gray-700"> {{loc.id_user}}: <span class="text-base-600">{{user.id}}</span> </p>
        <div class="flex">
          <p v-if="editable !== 'phone'" class="block text-sm font-medium text-gray-700"> {{loc.phone_number}}: <span class="text-base-600">{{user.phone}}</span></p>
          <input ref="phone_input" v-else v-model="editable_value" type="text" class="shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-300 rounded-md h-8" />
          <pencil-icon v-if="editable !== 'phone'" @click="editable = 'phone'; editable_value = user.phone;" class="ml-2 w-5 h-5 hover:text-base-600 cursor-pointer"/>
          <save-icon v-else class="ml-2 w-5 h-5 hover:text-base-600 cursor-pointer" @click="update_user({'phone': $refs.phone_input.value})"/>
        </div>

        <div class="flex">
          <p v-if="editable !== 'base_salary'" class="block text-sm font-medium text-gray-700"> {{loc.base_salary}}: <span class="text-base-600">{{user.base_salary}} {{ user.currency }}</span></p>
          <input ref="base_salary_input" v-else v-model="editable_value" type="text" class="shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-300 rounded-md h-8" />
          <pencil-icon v-if="editable !== 'base_salary'" @click="editable = 'base_salary'; editable_value = user.base_salary;" class="ml-2 w-5 h-5 hover:text-base-600 cursor-pointer"/>
          <save-icon v-else class="ml-2 w-5 h-5 hover:text-base-600 cursor-pointer" @click="update_user({'base_salary': $refs.base_salary_input.value})"/>
        </div>

        <div class="flex">
          <p v-if="editable !== 'guaranteed_allowances'" class="block text-sm font-medium text-gray-700"> {{loc.guaranteed_allowances}}: <span class="text-base-600">{{user.guaranteed_allowances}} {{ user.currency }}</span></p>
          <input ref="guaranteed_allowances_input" v-else v-model="editable_value" type="text" class="shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-300 rounded-md h-8" />
          <pencil-icon v-if="editable !== 'guaranteed_allowances'" @click="editable = 'guaranteed_allowances'; editable_value = user.guaranteed_allowances;" class="ml-2 w-5 h-5 hover:text-base-600 cursor-pointer"/>
          <save-icon v-else class="ml-2 w-5 h-5 hover:text-base-600 cursor-pointer" @click="update_user({'guaranteed_allowances': $refs.guaranteed_allowances_input.value})"/>
        </div>

        <div class="flex items-center justify-center pt-3">
          <span class="h-28 w-28 rounded-full overflow-hidden">
            <img v-if="user.company.picture" :src="user.company.picture" class="w-full h-full">

          </span>
        </div>

        <div class="pt-4">
          <button @click="isChangePassword = true;" type="button" class="w-full text-center px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-base-600 hover:bg-base-500 animation duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base-500">
            <i class="fas fa-lock mr-2"></i>
            {{loc.change_password}}
          </button>
        </div>

      </div>
      <div class="lg:w-1/3 p-4 space-y-4  bg-gray-50 rounded-md shadow-xl">
        <div class="flex justify-start">
          <h3 class="font-bold">{{loc.total_rewards}}</h3>
          <div class="tooltip font-medium text-sm">
            <InformationCircleIcon class="w-6 h-6 ml-2 text-base-500" />
            <span class="tooltiptext-rewards">{{loc.rewards_text}}</span>
          </div>
        </div>
        <bar-chart-icd :chart-data="chartData"/>
        <ul class="space-y-2.5 text-sm font-medium text-gray-600 tracking-tight pl-1.5">
          <li class="flex items-center gap-x-2">
            <div class="w-6 h-5" style="background-color: #D37800;"></div>
            <p>{{ loc.base_salary }} ({{ monetary(user.base_salary, user.currency) + '/' + loc.yearly }})</p>
          </li>
          <li class="flex items-center gap-x-2">
            <div class="w-6 h-5" style="background-color: #33A61A;"></div>
            <p>{{ loc.guaranteed_allowances }} ({{ monetary(user.guaranteed_allowances, user.currency) + '/' + loc.yearly }})</p>
          </li>
          <li class="flex items-center gap-x-2">
            <div class="w-6 h-5" style="background-color: #00D8FF;"></div>
            <p>{{ loc.annual_bonus }} ({{ monetary(user.annual_bonus, user.currency) + '/' + loc.yearly }})</p>
          </li>
          <li class="flex items-center gap-x-2">
            <div class="w-6 h-5" style="background-color: #1468A5;"></div>
            <p>{{ loc.benefits }} ({{ monetary(totalBenefitBudget, user.currency) + '/' + loc.yearly }})</p>
          </li>
        </ul>
        <div v-if="chartData" class="transform lg:translate-y-1.5 flex justify-between items-center font-bold bg-white py-2.5 px-1.5 shadow-xl rounded-md">
          <p>Total:</p>
          <span class="text-base-600 text-right">{{ monetary(chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0), user.currency) + ' / ' + loc.yearly }}</span>
        </div>
      </div>
    </div>
    <modal
      :open="isChangePassword"
      :hide_submit="true"
      :cancel-text="loc.cancel"
      @close="isChangePassword = false;"
      :title="`${loc.change_password}`"
      size="regular"
    >
      <change-password  @close="isChangePassword = false;" />
    </modal>
  </div>
</template>


<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import modal from "@/components/ui/modal";
import changePassword from "@/components/ui/changePassword";
import { Pie, Bar, Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)



import { mixins } from "vue-chartjs";
import barChartIcd from "@/components/BarChartIcd";

import {InformationCircleIcon, PencilIcon, SaveIcon} from "@heroicons/vue/solid";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default {
  name: "Profile",
  data(){
    return {
      isChangePassword: false,
      editable: "",
      editable_value: "",
      current_password: "",
      new_password: "",
      repeat_password: "",
      chartId: {
        type: String,
        default: 'bar-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 400
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Object,
        default: () => {}
      },
      chartOptions: {
        hoverBorderWidth: 20
      },
    }
  },
  components: {
    FontAwesomeIcon,
    Pie,
    Doughnut,
    changePassword,
    Bar,
    InformationCircleIcon,
    modal,
    PencilIcon,
    SaveIcon,
    barChartIcd
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user'
    }),
    chartData() {
      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        // labels: [this.loc.base_salary, this.loc.guaranteed_allowances, this.loc.annual_bonus, this.loc.flexible-benefits],
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#ce821d", "#499e36", "#00D8FF", "#1468a5"],
            data: [
              parseFloat(this.user.base_salary.replace(',', '')),
              parseFloat(this.user.guaranteed_allowances.replace(',', '')),
              parseFloat(this.user.annual_bonus.replace(',', '')),
              parseFloat(this.user.base_balance.replace(',', '')) + this.user.category_budgets.reduce((partialSum, a) => partialSum + parseFloat(a.amount), 0)
            ]
          }
        ]
      }
    },
    totalBenefitBudget() {
      let totalAmount = +this.user.base_balance;
      this.$store.state.auth.user.category_budgets.forEach((category) => {
        totalAmount += +category.amount;
      })
      return totalAmount;
    }
  },
  mounted() {
    console.log(this.user, 'user')
  },
  methods: {
    ...mapActions('auth', ['fetchUser']),
    async upload_profile_pic(e) {
      let data = new FormData()
      data.append('profile_picture', e.target.files[0])
      await this.axios.patch('v2/me/', data)
      await this.fetchUser()
    },
    async update_user(data) {
      await this.axios.patch('v2/me/', data)
      await this.fetchUser()
      this.editable = ''
    },
    calculateBudgetPercent(spent, budget) {
      if (budget <= 0) {
        console.error("Budžet mora biti veći od 0.");
        return 0;
      }

      const percentage = (spent / budget) * 100;
      return String(100 - percentage.toFixed(2));
    }
  }
}
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.71);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  left: -200px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext-rewards {
  visibility: hidden;
  width: 250px;
  background-color: white;
  text-align: left;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  left: -85px;
  z-index: 1;
}

.tooltip:hover .tooltiptext-rewards {
  visibility: visible;
}
</style>